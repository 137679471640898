import React from "react"
// import { Link, NavLink } from "react-router-dom"
import PropTypes from "prop-types"
import { MainImage } from "gatsby-plugin-image"
import { Link as NavLink } from "gatsby"

import logo from "./../../../static/images/brand/hidralstone.svg"
import logoPunchout from "./../../../static/images/brand/hidralstone-punchout.svg"
import banner from "./../../../static/images/placeholder/barra_cofin_FEDER.png"

const Footer = ({
    siteTheme,
    siteTitle,
    siteUrl,
    siteAuthor,
    siteSummary,
    siteAddress,
    siteSocial,
    lang,
    location,
}) => {
    if (lang === `en`) {
        return (
            <footer className="block">
                <hr className="mt-0 mb-5 bg-common o-11" />
                <div className="container">
                    <nav className={siteTheme === "dark" ? " navbar-dark" : " navbar-light"}>
                        <h2 className="sr-only visually-hidden">Footer</h2>
                        <div className={"row justify-content-between gx-4"}>
                            <div className={"col-12 col-lg-4"}>
                                <div className={"block"}>
                                    <NavLink to="/en" title={"Hidralstone"}>
                                        {siteTheme === "dark" ? (
                                            <MainImage
                                                className="my-auto img-fluid"
                                                src={logoPunchout}
                                                width={180}
                                                placeholder="none"
                                                alt="hs"
                                            />
                                        ) : (
                                            <MainImage
                                                className="my-auto img-fluid"
                                                src={logo}
                                                width={180}
                                                placeholder="none"
                                                alt="hs"
                                            />
                                        )}
                                        <p className="sr-only visually-hidden">
                                            {siteTitle}
                                        </p>
                                    </NavLink>
                                </div>
                                <div className={"container-lg-base mt-3"}>
                                    {siteAuthor && (
                                        <p className="small">
                                            <a
                                                target="_self"
                                                rel="noreferrer noopener"
                                                href={`${siteUrl || ``}`}
                                            >
                                                <strong>{siteAuthor}</strong>
                                            </a>
                                            {` `}
                                            {siteSummary || null}
                                            {` `}
                                            {/* Find us on{" "}
                                            <a
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                href={`https://pt.linkedin.com/company/${
                                                    siteSocial || ``
                                                }`}
                                            >
                                                LinkedIn
                                            </a> */}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-lg">
                                <div className="row gx-1">
                                    <section className={"col"}>
                                        <h3 className="smallcaps ls-1 text-uppercase fw-black lh-1">
                                            Hidralstone
                                        </h3>
                                        <ul className="navbar-nav minimal-nav flex-column mt-auto">
                                            <li className="nav-item">
                                                <NavLink className="nav-link ff-aux fw-medium" activeClassName="active" to="/en/portugal2020" title={"About Us"}>
                                                    Portugal 2020
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link ff-aux fw-medium" activeClassName="active" to="/en/#about" title={"About Us"}>
                                                    About
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </section>
                                    <section className={"col"}>
                                        <h3 className="smallcaps ls-1 text-uppercase fw-black lh-1">
                                            Support
                                        </h3>
                                        <ul className="navbar-nav minimal-nav flex-column mt-auto">
                                            <li className="nav-item">
                                                <a className="nav-link ff-aux fw-medium" href="mailto:info@hidralstone.com" target="_blank" rel="noreferrer noopener">Contact</a>
                                            </li>
                                        </ul>
                                    </section>
                                    <div className={"col-md text-center text-md-end mt-4 mt-md-0"}>
                                        <a
                                            target="_blank"
                                            rel="noreferrer noopener"
                                            href={`http://www.alentejo.portugal2020.pt/`}
                                        >
                                            <MainImage className="my-auto img-fluid" src={banner} width={'100%'} placeholder="none" alt="banner Portugal 2020" />
                                        </a>
                                        {/* <div className="input-grouped bg-light p-1 rounded-pill">
                                            <div className="row">
                                                <div className="col">
                                                    <input type="text" className="form-control rounded-pill px-3" aria-label="Text input" placeholder="Your email" />
                                                </div>
                                                <div className="col-auto">
                                                    <a href="" className="btn btn-primary rounded-circle"><i className="bi bi-arrow-return-left"></i></a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="row align-items-center justify-content-between gx-4 gy-1 pt-4 pb-3 pb-lg-4">
                        <div className="col-md-4 col-lg-2 order-lg-2 text-center text-md-start">
                            <ul className="navbar-nav minimal-nav flex-row small justify-content-center justify-content-md-start">
                                <li className="nav-item"><a href={`https://facebook.com/${siteSocial || ``}`} className="nav-link ff-aux fw-medium">facebook</a></li>
                                <li className="nav-item ms-1"><a href={`https://pt.linkedin.com/company/${siteSocial || ``}`} className="nav-link ff-aux fw-medium">linkedin</a></li>
                            </ul>
                        </div>
                        <div className="col-md-8 col-lg text-center text-md-end order-lg-3">
                            <p className="small lh-title my-auto">{`${siteAddress || `Zona Industrial da Adua, Lote LC2 | 7050-101 MONTEMOR-O-NOVO`}`}</p>
                        </div>
                        <div className="col col-lg-4 order-lg-1 text-center text-md-start">
                            <p className="small my-auto">
                                Copyright © {new Date().getFullYear()}, {` `}{" "}
                                <a href={`${siteUrl || ``}`} title={"Hidralstone"}>
                                    Hidralstone
                                </a>
                                . All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    } else {
        return (
            <footer className="block">
                <hr className="mt-0 mb-5 bg-common o-11" />
                <div className="container">
                    <nav className={siteTheme === "dark" ? " navbar-dark" : " navbar-light"}>
                        <h2 className="sr-only visually-hidden">Footer</h2>
                        <div className={"row justify-content-between gx-4"}>
                            <div className={"col-12 col-lg-4"}>
                                <div className={"block"}>
                                    <NavLink to="/" title={"Hidralstone"}>
                                        {siteTheme === "dark" ? (
                                            <MainImage
                                                className="my-auto img-fluid"
                                                src={logoPunchout}
                                                width={180}
                                                placeholder="none"
                                                alt="hs"
                                            />
                                        ) : (
                                            <MainImage
                                                className="my-auto img-fluid"
                                                src={logo}
                                                width={180}
                                                placeholder="none"
                                                alt="hs"
                                            />
                                        )}
                                        <p className="sr-only visually-hidden">
                                            {siteTitle}
                                        </p>
                                    </NavLink>
                                </div>
                                <div className={"container-lg-base mt-3"}>
                                    {siteAuthor && (
                                        <p className="small">
                                            <a
                                                target="_self"
                                                rel="noreferrer noopener"
                                                href={`${siteUrl || ``}`}
                                            >
                                                <strong>{siteAuthor}</strong>
                                            </a>
                                            {` `}
                                            {siteSummary || null}
                                            {` `}
                                            {/* Find us on{" "}
                                            <a
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                href={`https://pt.linkedin.com/company/${
                                                    siteSocial || ``
                                                }`}
                                            >
                                                LinkedIn
                                            </a> */}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-lg">
                                <div className="row gx-1">
                                    <section className={"col"}>
                                        <h3 className="smallcaps ls-1 text-uppercase fw-black lh-1">
                                            Hidralstone
                                        </h3>
                                        <ul className="navbar-nav minimal-nav flex-column mt-auto">
                                            <li className="nav-item">
                                                <NavLink className="nav-link ff-aux fw-medium" activeClassName="active" to="/portugal2020" title={"About Us"}>
                                                    Portugal 2020
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link ff-aux fw-medium" activeClassName="active" to="/#about" title={"About Us"}>
                                                    Sobre nós
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </section>
                                    <section className={"col"}>
                                        <h3 className="smallcaps ls-1 text-uppercase fw-black lh-1">
                                            Suporte
                                        </h3>
                                        <ul className="navbar-nav minimal-nav flex-column mt-auto">
                                            <li className="nav-item">
                                                <a className="nav-link ff-aux fw-medium" href="mailto:info@hidralstone.com" target="_blank" rel="noreferrer noopener">Contactos</a>
                                            </li>
                                        </ul>
                                    </section>
                                    <div className={"col-md text-center text-md-end mt-4 mt-md-0"} >
                                        <a
                                            target="_blank"
                                            rel="noreferrer noopener"
                                            href={`http://www.alentejo.portugal2020.pt/`}
                                        >
                                            <MainImage className="my-auto img-fluid" src={banner} width={'100%'} placeholder="none" alt="banner Portugal 2020" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="row align-items-center justify-content-between gx-4 gy-1 pt-4 pb-3 pb-lg-4">
                        <div className="col-md-4 col-lg-2 order-lg-2 text-center text-md-start">
                            <ul className="navbar-nav minimal-nav flex-row small justify-content-center justify-content-md-start">
                                <li className="nav-item"><a href={`https://facebook.com/${siteSocial || ``}`} className="nav-link ff-aux fw-medium">facebook</a></li>
                                <li className="nav-item ms-1"><a href={`https://pt.linkedin.com/company/${siteSocial || ``}`} className="nav-link ff-aux fw-medium">linkedin</a></li>
                            </ul>
                        </div>
                        <div className="col-md-8 col-lg text-center text-md-end order-lg-3">
                            <p className="small lh-title my-auto">{`${siteAddress || `Zona Industrial da Adua, Lote LC2 | 7050-101 MONTEMOR-O-NOVO`}`}</p>
                        </div>
                        <div className="col col-lg-4 order-lg-1 text-center text-md-start">
                            <p className="small my-auto">
                                Copyright © {new Date().getFullYear()}, {` `}{" "}
                                <a href={`${siteUrl || ``}`} title={"Hidralstone"}>
                                    Hidralstone
                                </a>
                                . Todos os direitos reservados
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

Footer.propTypes = {
    lang: PropTypes.string,
    siteTitle: PropTypes.string,
    siteTheme: PropTypes.string,
}

Footer.defaultProps = {
    lang: `pt`,
    siteTitle: `hidralstone`,
    siteTheme: ``,
}

export default Footer
