/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "./seo"

import Header from "../components/header"
import Footer from "../components/footer"

const isBrowser = typeof window !== "undefined"
const Layout = ({ location, children, lang, theme, title }) => {
    useEffect(() => {
        if (isBrowser) {
            window.onscroll = function () {
                // body.scrollTop is deprecated and no longer available on Firefox
                var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                var progress = (bodyScrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) * 100

                if (document.getElementById("scroller")) {
                    document.getElementById("scroller").style.width = progress + "%"
                }

                if (document.getElementById("navbarLocal")) {
                    if (bodyScrollTop > 77) {
                        document.getElementById("navbarLocal").classList.add('fixed-top')
                        document.getElementById("navbarLocal").classList.add('bg-inherit')
                    } else if (bodyScrollTop <= 77){
                        document.getElementById("navbarLocal").classList.remove('fixed-top')
                        document.getElementById("navbarLocal").classList.remove('bg-inherit')
                    }
                }
            }
        }
    }, [])

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    author {
                        name
                        summary
                    }
                    siteUrl
                    social {
                        linkedin
                    }
                }
            }
        }
    `)
    
    return (
        <div className={"wrapper overflow-x-hidden" + (theme === "dark" ? " bg-dark text-white" : "")}>
            <Seo title={title} lang={lang} />
            <Header
                siteTheme={theme}
                siteTitle={data.site.siteMetadata?.title}
                location={location.pathname}
                lang={lang}
            />
            <React.Fragment>{children}</React.Fragment>
            <Footer
                siteTheme={theme}
                siteTitle={data.site.siteMetadata?.title}
                siteAuthor={data.site.siteMetadata?.author?.name}
                siteUrl={data.site.siteMetadata?.siteUrl}
                siteSummary={data.site.siteMetadata?.author?.summary}
                siteAddress={data.site.siteMetadata?.author?.address}
                siteSocial={data.site.siteMetadata?.social?.linkedin}
                location={location.pathname}
                lang={lang}
            />
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.string,
}

export default Layout
