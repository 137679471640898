import React from "react";
import PropTypes from "prop-types"
import { MainImage } from "gatsby-plugin-image"
import { Link as NavLink } from "gatsby"

import logo from "./../../../static/images/brand/hidralstone.svg"
import logoPunchout from "./../../../static/images/brand/hidralstone-punchout.svg"

const Header = ({ siteTitle, siteTheme, lang, location }) => {
	if (lang === `en`) {
		return (
			<header className={"navbar navbar-expand-md py-4" + (siteTheme === "dark" || siteTheme === "mix" ? " navbar-dark border-dim" : " navbar-light border-light") + (siteTheme === "mix" ? " bg-primary" : "")} id="mainNav">
				<div className="container">
					<NavLink to={"/en"} title={"Hidralstone"} title="Hidralstone" className="navbar-brand align-items-center text-dark text-decoration-none py-0">
						{siteTheme === "dark" || siteTheme === "mix" ? (
							<MainImage
								className="d-block my-auto img-fluid"
								src={logoPunchout}
								width={160}
								placeholder="none"
								alt="hs"
							/>
						) : (
							<MainImage
								className="d-block my-auto img-fluid"
								src={logo}
								width={160}
								placeholder="none"
								alt="hs"
							/>
						)}
						<h1 className="sr-only visually-hidden">{siteTitle}</h1>
					</NavLink>
					<button className="navbar-toggler btn bg-dark text-white rounded-0 px-2 py-1 border-0 no-focus" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="mdi mdi-menu fs-4 lh-copy align-middle"></span>
					</button>
					<nav className="collapse navbar-collapse mt-2 mt-sm-0" id="navbarContent" data-bs-parent="#mainNav">
						<h2 className="sr-only visually-hidden">Navigation</h2>
						<ul className="navbar-nav col-sm mb-2 mb-sm-0 ms-lg-6">
							<li className="nav-item">
								<NavLink to="/en" className={"nav-link ff-aux fw-medium px-md-3" + (location === '/en/' ? " active" : "")} activeClassName="active">
									Welcome
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to="/en/portugal2020" className={"nav-link ff-aux fw-medium px-md-3" + (location === '/en/portugal2020/' ? " active" : "")} activeClassName="active">
									Portugal2020
								</NavLink>
							</li>
							{/* <li className="nav-item btn-group dropdown">
								<NavLink to="/en/products" className={"btn nav-link ff-aux fw-medium ps-3" + (location === '/en/products/' || location === '/en/products/tiles' || location === '/en/products/tiles/' || location === '/en/products/slabs' || location === '/en/products/slabs/' || location === '/en/products/mosaic' || location === '/en/products/mosaic/' ? " active" : "")} activeClassName="active">
									Products
								</NavLink>
								<button type="button" className="btn nav-link dropdown-toggle dropdown-toggle-split no-focus ps-0 pe-3" id="solDropdown" data-bs-toggle="dropdown" aria-expanded="false">
									<span className="visually-hidden">Toggle Dropdown</span>
								</button>
								<ul className="dropdown-menu p-1 border-none shadow" aria-labelledby="solDropdown">
									<li className={"dropdown-item p-0 rounded" + (location === '/en/products/tiles' || location === '/en/products/tiles/' ? " text-primary" : "")}>
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light" to="/products/tiles" title={"EN"}>
											Tiles
										</NavLink>
									</li>
									<li className={"dropdown-item p-0 rounded" + (location === '/en/products/slabs' || location === '/en/products/slabs/' ? " text-primary" : "")}>
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light" to="/products/slabs" title={"EN"}>
											Slabs
										</NavLink>
									</li>
									<li className={"dropdown-item p-0 rounded" + (location === '/en/products/mosaic' || location === '/en/products/mosaic/' ? " text-primary" : "")}>
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light" to="/products/mosaic" title={"EN"}>
											Mosaics
										</NavLink>
									</li>
								</ul>
							</li> */}
						</ul>

						<ul className="navbar-nav col-md col-lg-3 justify-content-md-end ms-md-auto">
							<li className="nav-item dropdown">
								<button className="btn nav-link ff-aux fw-medium dropdown-toggle no-focus" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
									EN
								</button>
								<ul className="dropdown-menu dropdown-menu-end p-1 border-none shadow" aria-labelledby="languageDropdown" data-bs-popper>
									<li className="dropdown-item p-0 rounded">
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light text-primary" to={location.split("/")[2] === undefined ? "/" : "/" + location.split("/")[2]} title={"EN"}>
											PT
										</NavLink>
									</li>
									<li className="dropdown-item p-0 rounded">
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light text-primary" to={location.split("/")[2] === undefined ? "/en" : "/en/" + location.split("/")[2]} title={"PT"}>
											EN
										</NavLink>
									</li>
								</ul>
							</li>
							<li className="nav-item">
								<NavLink to="/en/#about" className="nav-link ff-aux fw-medium" activeClassName="active">
									About
								</NavLink>
							</li>
						</ul>
					</nav>
				</div>
			</header>

		)
	} else {
		return (
			<header className={"navbar navbar-expand-md py-4" + (siteTheme === "dark" || siteTheme === "mix" ? " navbar-dark border-dim" : " navbar-light border-light") + (siteTheme === "mix" ? " bg-secondary" : "")} id="mainNav">
				<div className="container">
					<NavLink to={"/"} title="Hidralstone" className="navbar-brand align-items-center text-dark text-decoration-none py-0">
						{siteTheme === "dark" || siteTheme === "mix" ? (
							<MainImage
								className="d-block my-auto img-fluid"
								src={logoPunchout}
								width={160}
								placeholder="none"
								alt="hs"
							/>
						) : (
							<MainImage
								className="d-block my-auto img-fluid"
								src={logo}
								width={160}
								placeholder="none"
								alt="hs"
							/>
						)}
						<h1 className="sr-only visually-hidden">{siteTitle}</h1>
					</NavLink>
					<button className="navbar-toggler btn bg-dark text-white rounded-0 px-2 py-1 border-0 no-focus" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="mdi mdi-menu fs-4 lh-copy align-middle"></span>
					</button>
					<nav className="collapse navbar-collapse pt-2 pt-sm-0" id="navbarContent" data-bs-parent="#mainNav">
						<h2 className="sr-only visually-hidden">Navigation</h2>
						<ul className="navbar-nav col-sm mb-2 mb-sm-0 ms-lg-6">
							<li className="nav-item">
								<NavLink to="/" className="nav-link ff-aux fw-medium px-md-3" activeClassName="active">
									Bem-vindo
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to="/portugal2020" className="nav-link ff-aux fw-medium px-md-3" activeClassName="active">
									Portugal2020 <i></i>
								</NavLink>
							</li>
							{/* <li className="nav-item btn-group dropdown">
								<NavLink className={"ps-3 btn nav-link ff-aux fw-medium" + (location === '/products/tiles' || location === '/products/tiles/' || location === '/products/slabs' || location === '/products/slabs/' || location === '/products/mosaic' || location === '/products/mosaic/' ? " active" : "")} activeClassName="active" to="/products" title={"EN"}>
									Produtos
								</NavLink>
								<button type="button" className="btn nav-link dropdown-toggle dropdown-toggle-split no-focus pe-3" id="solDropdown" data-bs-toggle="dropdown" aria-expanded="false">
									<span className="visually-hidden">Toggle Dropdown</span>
								</button>
								<ul className="dropdown-menu p-1 border-none shadow" aria-labelledby="solDropdown">
									<li className={"dropdown-item p-0 rounded" + (location === '/products/tiles' || location === '/products/tiles/' ? " text-primary" : "" )}>
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light" to="/products/tiles" title={"EN"}>
											Ladrilhos
										</NavLink>
									</li>
									<li className={"dropdown-item p-0 rounded" + (location === '/products/slabs' || location === '/products/slabs/' ? " text-primary" : "")}>
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light" to="/products/slabs" title={"EN"}>
											Lajetas
										</NavLink>
									</li>
									<li className={"dropdown-item p-0 rounded" + (location === '/products/mosaic' || location === '/products/mosaic/' ? " text-primary" : "")}>
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light" to="/products/mosaic" title={"EN"}>
											Mosaicos
										</NavLink>
									</li>
								</ul>
							</li> */}
						</ul>

						<ul className="navbar-nav col-md col-lg-3 justify-content-end ms-auto">
							<li className="nav-item dropdown">
								<button className="btn nav-link ff-aux fw-medium dropdown-toggle no-focus" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
									PT
								</button>
								<ul className="dropdown-menu dropdown-menu-end p-1 border-none shadow" aria-labelledby="languageDropdown" data-bs-popper>
									<li className="dropdown-item p-0 rounded">
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light text-primary" to={location.split("/")[0] === "/" ? "/" : location} title={"EN"}>
											PT
										</NavLink>
									</li>
									<li className="dropdown-item p-0 rounded">
										<NavLink className="nav-link ff-aux fw-medium lh-title px-3 text-inherit" activeClassName="bg-light text-primary" to={(location.split("/")[1] === undefined) ? "/en" : ("/en/" + location.split("/")[1])} title={"PT"}>
											EN
										</NavLink>
									</li>
								</ul>
							</li>
							<li className="nav-item">
								<NavLink to="/#about" className="nav-link ff-aux fw-medium" activeClassName="active">
									Sobre nós
								</NavLink>
							</li>
						</ul>
					</nav>
				</div>
			</header>
		)
	}
}

Header.propTypes = {
	lang: PropTypes.string,
	siteTitle: PropTypes.string,
	siteTheme: PropTypes.string,
}

Header.defaultProps = {
	lang: `pt`,
	siteTitle: `hidralstone`,
	siteTheme: ``,
}

export default Header
